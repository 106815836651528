<template>
  <div>
    <div class="dgbox">
      <div class="dgtitle">Alert</div>
      <div class="dgcontent">Want to Logout?</div>
      <div class="dgfooter">
        <input
          type="button"
          class="btn btn-primary"
          value="Logout"
          @click="logoutBtn"
        />
        <input
          type="button"
          class="btn btn-danger"
          value="Cancel"
          @click="cancelBtn"
        />
      </div>
    </div>
    <div class="bgdark"></div>
  </div>
</template>
<script>
export default {
  emits: ["logout", "cancel"],
  methods: {
    cancelBtn() {
      this.$emit("cancel");
    },
    logoutBtn() {
      this.$emit("logout");
    },
  },
};
</script>
<style scoped>
.bgdark {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  clear: both;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}
.dgbox {
  position: absolute;
  margin: 0px auto !important;
  width: 350px;
  height: auto;
  background-color: white;
  z-index: 1000;
  border-radius: 8px;
  text-align: left;
  justify-content: left;
  top: 100px;
  left: 0px;
  right: 0px;
}

.dgtitle {
  padding: 8px;
  border-bottom: 1px solid lightgray;
}

.dgcontent {
  font-size: 18px;
  padding: 12px;
  border-bottom: 1px solid lightgray;
}
.dgfooter {
  padding: 12px;
  justify-content: right;
  text-align: right;
}
.dgfooter .btn {
  margin-left: 5px;
  padding: 5px;
  font-size: 14px;
}
</style>
