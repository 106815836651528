<template>
  <div class="loader-div">
    <i class="fa fa-spinner fa-spin"></i>
    <br />
    <br />
    <br />
    <b>{{ LoaderText }}</b>
  </div>
</template>

<script>
export default {
  props: ["LoaderText"],
};
</script>
<style scoped>
.loader-div {
  position: relative;
  margin: 0px auto !important;
  width: 150px;
  height: 150px;
  border-radius: 15px;
  text-align: center;
  top: 5px;
  background-color: white;
}
.fa-spinner {
  position: relative;
  margin: 0px auto !important;
  top: 25%;
  font-size: 45px;
}
</style>
